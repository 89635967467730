import { combineReducers } from 'redux'
import {
  GETTING_WIDGET_SETTINGS,
  GET_SUCCESS_WIDGET_SETTINGS,
  GET_FAIL_WIDGET_SETTINGS,
  POSTING_WIDGET_SETTINGS,
  POST_SUCCESS_WIDGET_SETTINGS,
  POST_FAIL_WIDGET_SETTINGS,
  UPDATE_FIELD,
  UPDATE_THEME,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  CROP_OPEN,
  CROP_PHOTO,
  CROP_CLOSE,
  CROP_DELETE,
} from '../actions/ActionTypes'

const initialWidgetSettingsState = {
  venueId: '',
  widgetTemplateTheme: 'LIGHT',

  font: 'Roboto',
  reservationHoldTimeMins: 15,
  partySizeType: 'TOTAL',
  enableFieldTime: false,
  enableFieldBirthday: false,
  enableFieldAdditionalInfo: false,
  birthdayType: 'Hidden',
  salutationType: 'Hidden',
  buttonText: 'Reservations',
  buttonColor: '#093A75',
  buttonId: 1,

  colorPrimary: '#C8C8C8',
  colorButton: '#C8C8C8',
  fontsColorPrimary: '#5A5A5A',
  fontsColorSecondary: '#8A8A8A',
  fontsColorButton: '#FFFFFF',
  fontsColorLinks: '#3592E2',
  fontsColorCheckoutInactive: '#B0B0B0',
  fontsColorCheckoutActive: '#FFFFFF',
  fontsColorError: '#D01A21',
  colorBackground: '#EAEAEA',
  colorBackgroundOpacity: 1.0,
  colorWidgetBackground: '#EEEEEE',
  colorWidgetBackgroundOpacity: 1.0,
  colorCellBackground: '#FFFFFF',
  colorCellBackgroundOpacity: 1.0,
  colorLines: '#F4F4F3',
  colorLinesOpacity: 0.5,
  colorCheckoutBackground: '#FFFFFF',
  colorCheckoutBackgroundOpacity: 0.5,
  colorHoverOutline: '#C1C1C1',
  colorHoverOutlineOpacity: 0.5,

  hideCalendar: false,

  lightTheme: {},
  darkTheme: {},
  recaptchaOn: false,

  floorplanClickColor: '#800080',
  floorplanClickOpacity: 0.4,
  floorplanHoverColor: '#FFFF00',
  floorplanHoverOpacity: 0.4,
  floorplanAvailableColor: '#008000',
  floorplanAvailableOpacity: 0.4,
  floorplanUnavailableColor: '#FF0000',
  floorplanUnavailableOpacity: 0.4,
}
const widgetSettings = (state = initialWidgetSettingsState, action) => {
  switch (action.type) {
    case GET_SUCCESS_WIDGET_SETTINGS:
      return { ...state, ...action.widgetSettings }
    case UPDATE_FIELD:
      if (action.fieldName === 'colorPrimary') {
        return { ...state, colorPrimary: action.value, buttonColor: action.value, fontsColorLinks: action.value }
      }
      if (action.fieldName === 'hideCalendar') {
        window.HIDE_CALENDAR = action.value
      }
      return { ...state, [action.fieldName]: action.value }

    case UPDATE_THEME: {
      const theme = state.widgetTemplateTheme === 'LIGHT' ? state.lightTheme : state.darkTheme
      const newThemeState = {
        colorPrimary: theme.color_primary || '',
        colorButton: theme.color_button || '',
        fontsColorPrimary: theme.fonts_color_primary || '',
        fontsColorSecondary: theme.fonts_color_secondary || '',
        fontsColorButton: theme.fonts_color_button || '',
        fontsColorLinks: theme.fonts_color_links || '',
        fontsColorCheckoutInactive: theme.fonts_color_checkout_inactive || '',
        fontsColorCheckoutActive: theme.fonts_color_checkout_active || '',
        fontsColorError: theme.fonts_color_error || '',
        colorBackground: theme.color_background || '',
        colorBackgroundOpacity: theme.color_background_opacity || '',
        colorCellBackground: theme.color_cell_background || '',
        colorCellBackgroundOpacity: theme.color_cell_background_opacity || '',
        colorLines: theme.color_lines || '',
        colorLinesOpacity: theme.color_lines_opacity || '',
        colorWidgetBackground: theme.color_widget_background || '',
        colorWidgetBackgroundOpacity: theme.color_widget_background_opacity || '',
        colorCheckoutBackground: theme.color_checkout_background || '',
        colorCheckoutBackgroundOpacity: theme.color_checkout_background_opacity || '',
        colorHoverOutline: theme.color_hover_outline || '',
        colorHoverOutlineOpacity: theme.color_hover_outline_opacity || '',
        buttonId: theme.button_id || '1',
      }
      return { ...state, ...newThemeState }
    }
    case UPLOAD_PHOTO_PREVIEW:
      return { ...state, img_url: action.preview }
    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        img_url: `/.h/download/${action.upload.url_key}`,
        img_blob_key: action.upload.post_key,
        colorBackgroundOpacity: Math.min(state.colorBackgroundOpacity, 0.5),
      }
    case CROP_OPEN:
      return { ...state, isCropping: true }
    case CROP_PHOTO:
      return { ...state, cropData: action.coords }
    case CROP_CLOSE:
      return { ...state, isCropping: false }
    case CROP_DELETE:
      return { ...state, img_url: null, img_blob_key: null, cropData: null }
    default:
      return state
  }
}

const initialAppState = {
  isGetting: false,
  isPosting: false,
  isFailure: false,
  errors: {}, // errors for form fields
}
const appState = (state = initialAppState, action) => {
  switch (action.type) {
    case UPDATE_FIELD: {
      let errorState = state.errors
      if (action.error) {
        errorState = { ...errorState, [action.fieldName]: action.error }
      } else if (state.errors[action.fieldName] && !action.error) {
        errorState = { ...errorState }
        delete errorState[action.fieldName]
      }
      return { ...state, errors: errorState }
    }
    case GETTING_WIDGET_SETTINGS:
      return { ...state, isGetting: true }
    case GET_SUCCESS_WIDGET_SETTINGS:
      return { ...state, isFailure: false, isGetting: false }
    case GET_FAIL_WIDGET_SETTINGS:
      return { ...state, isFailure: true, isGetting: false }
    case POSTING_WIDGET_SETTINGS:
      return { ...state, isPosting: true }
    case POST_SUCCESS_WIDGET_SETTINGS:
      return { ...state, isFailure: false, isPosting: false }
    case POST_FAIL_WIDGET_SETTINGS:
      return { ...state, isFailure: true, isPosting: false }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  widgetSettings,
  appState,
})

export default rootReducer
